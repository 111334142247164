/**
* Generated automatically at built-time (2025-01-02T07:21:07.083Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkueche-schwarz",templateKey: "sites/102-a0cbf262-fa80-4132-83d2-cc7acd7b2000"};