/**
* Generated automatically at built-time (2025-01-02T07:21:07.106Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "ideen-outdoorkueche",templateKey: "sites/102-537f8f6c-40e6-492c-b6f8-35278cbe2481"};