/**
* Generated automatically at built-time (2025-01-02T07:21:07.015Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkuechenkollektionen-ueberblick",templateKey: "sites/102-b1559e9b-dab3-4c23-9469-ba14f1a20bcb"};